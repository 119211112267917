import { Component, DestroyRef, SecurityContext, computed, effect, inject, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { EventBusService } from '@big-direkt/event-bus';
import { PageNavigationService } from '@big-direkt/form/view';
import { UiRepository } from '@big-direkt/state/ui';
import { UserRepository, UserService } from '@big-direkt/state/user';
import { EnvironmentService, EtagService, RESPONSE } from '@big-direkt/utils/environment';
import { MetadataModel, NodeTypes, PlatformService, type NodeModel, type ResolvedRouteData } from '@big-direkt/utils/shared';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'big-content-component-base',
    template: '',
})
export abstract class ContentBaseComponent implements OnInit {
    protected abstract readonly userRepository: UserRepository;
    // User Service is not injectable in base component, because User Profile from userModule is nor injected correctly.
    protected abstract readonly userService: UserService;

    public readonly pageNavigationService = inject(PageNavigationService);
    public readonly eventBus = inject(EventBusService);
    public readonly environmentService = inject(EnvironmentService);
    protected readonly uiRepository = inject(UiRepository);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly destroyRef = inject(DestroyRef);
    private readonly platformService = inject(PlatformService);
    private readonly response = inject(RESPONSE, { optional: true });
    private readonly etagService = inject(EtagService);

    public readonly securityContext: SecurityContext = SecurityContext.HTML;
    public readonly nodeTypes: typeof NodeTypes = NodeTypes;
    public readonly isFormShown = this.uiRepository.isFormShown;

    public readonly showWizard = computed(() => {
        const currentPage = this.pageNavigationService.getCurrentPageSignal()();

        if (!currentPage) {
            return false;
        }

        return this.node.type === NodeTypes.Form && currentPage.isWizardVisible;
    });

    public readonly isFirstFormPage = computed(() => this.pageNavigationService.getCurrentPageSignal()()?.previousAccessibleLeaf === undefined);

    public node!: NodeModel;

    public get hasIntroduction(): boolean {
        const { node } = this;

        return !!(node.heading ?? node.introduction ?? node.publicationDate ?? node.publicationLocation);
    }

    public constructor() {
        effect(() => {
            this.uiRepository.setIsWizardShown(this.showWizard());
        });
    }

    public ngOnInit(): void {
        this.activatedRoute.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
            this.updateContentBase(data as ResolvedRouteData);
        });
    }

    protected updateContentBase(data: ResolvedRouteData): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.node = data.content!;

        if (this.platformService.isPlatformServer() && this.node.type === this.nodeTypes.Error && this.node.httpStatuscode !== undefined) {
            this.response?.status(this.node.httpStatuscode);
        }

        if (this.platformService.isPlatformServer()) {
            const etag = this.etagService.getLatestEtag();

            if (etag) {
                this.response?.setHeader('etag', etag.id);
                this.response?.setHeader('last-modified', etag.date.toUTCString());
            }
        }

        this.updateContentDetails(data);

        void this.trackPageInitializedEvent();
    }

    protected async trackPageInitializedEvent(): Promise<void> {
        this.eventBus.emit({
            key: 'page_initialized_event',
            data: {
                envWork: this.environmentService.workEnv,
                siteName: this.node.heading,
                pageType: this.node.type,
                pageCat1: this.node.breadcrumbs?.[1]?.title ?? '',
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                pageCat2: this.node.breadcrumbs?.[2]?.title ?? '',
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                pageCat3: this.node.breadcrumbs?.[3]?.title ?? '',
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                pageCat4: this.node.breadcrumbs?.[4]?.title ?? '',
                pageKeywords: this.node.metadata?.find((meta: MetadataModel) => meta.attributes.name === 'keywords')?.attributes.content ?? '',
                pageID: this.node.nodeId.toString() || '',
                isLoggedIn: !!this.userRepository.isLoggedIn(),
                customerSegment: (await firstValueFrom(this.userService.getCustomerSegment())) ?? '',
            },
        });
    }

    protected abstract updateContentDetails(data: ResolvedRouteData): void;
}
